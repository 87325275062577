import React, { useEffect, useState } from 'react'
import { setLoading } from '../../../slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../../BaseURL';
import { apiConnector } from '../../../services/apiConnector';
import toast from 'react-hot-toast';
import Loader from '../../../Loading/Loader';
import AttendanceCalendar from './AttendanceCalender/AttendanceCalendar';
import AttendanceTable from './AttendanceTable/AttendanceTable';

const Attendence = () => {
  const date = new Date();
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const weeks = ["Sunday","Monday","Tuesday","Thursday","Friday","Saturday"];
  const d = new Date();
  let month = months[d.getMonth()];
  let day = weeks[d.getDay()];
  let dat = d.getDate();

  const [isPresent, setIsPresent] = useState(false);
  const {loading} = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const handlePresent = (e) => {
    e.preventDefault();
    setIsPresent(!isPresent);
  };

  // const handleInputChange = (e) =>{
  //   setIsPresent({...isPresent,
  //     [e.target.name]: e.target.value
  //   });
  // }

  const id = user._id;
 
  const markAttendance = async(e)=>{
      e.preventDefault();
      // console.log(isPresent);
      dispatch(setLoading(true));
      try {
          const response = await apiConnector("post",`${BASE_URL}/attendence/add-attendence`,{
            id,isPresent});

          if(!response.data.success){
              throw new Error(response.data.message);
          }

          toast.success("Attendance Marked Succesfully");

          window.location.reload();

      } catch (error) {
          console.log("Can't fetch Data due to",error);
          toast.error(error?.response.data.message);
      }
      dispatch(setLoading(false));
  }

  const [attendanceData, setAttendanceData] = useState([]);
  const [userDataAttendence, setUserAttendenceData] = useState([]);

  const getAllAttendences = async()=>{
    dispatch(setLoading(true));
    try {
        const response = await apiConnector("get",`${BASE_URL}/attendence/get-attendence/${id}`);

        if(!response.data.success){
            throw new Error(response.data.message);
        }

        // toast.success("Attendance Fetched Succesfully");
        setAttendanceData(response.data.data.Attendence);
        setUserAttendenceData(response.data.data);

    } catch (error) {
        console.log("Can't fetch Data due to",error);
        toast.error(error?.response.data.message);
    }
    dispatch(setLoading(false));
}

  useEffect(()=>{
    getAllAttendences();
  },[])

  return (
    <div className=' w-full h-full flex flex-col gap-2 mt-3 font-poppins ' >
    <div className=' flex items-center justify-between ' >
      <h1 className=' text-md md:text-lg font-semibold ' >Attendance</h1>
      <div className='flex items-center flex-col sm:items-end text-sm ' >
                    <p>{day}</p>
                    <p className='font-semibold' >{dat} {month} {d.getFullYear()},<span className='text-[#5D59D9]' >{date.toLocaleString().split(',').at("-1")}</span></p>
      </div>
    </div>
    <div className=' h-[1px] w-full bg-slate-300 ' ></div>
    {
      loading && (<Loader/>)
    }
    {
      !loading && (
      <div className=' flex flex-col gap-5 ' >
      <p className=' text-xl font-poppins text-[#081321] text-center font-semibold ' >Mark Your Today's Attendence</p>
      <form className=' flex flex-col gap-3 justify-center items-center ' >
      <div className="flex p-1 gap-x-1 my-2 rounded-full max-w-max bg-[#081321] ">
                <button
                        className={`${isPresent === true
                        ?"bg-green-500 text-white" 
                        : "bg-transparent text-gray-400 hover:bg-green-500 hover:text-white "}
                        py-2 px-5 rounded-full transition-all duration-200 `}
                        onClick={handlePresent}
                    >
                        Present
                </button>
                <button
                    className={`${isPresent === false
                    ? "bg-red-500 text-white"
                    : "bg-transparent text-gray-400 hover:bg-red-500 hover:text-white "} 
                    py-2 px-5 rounded-full transition-all duration-200`}
                    onClick={handlePresent}
                >
                    Absent
                </button>
            </div>
        <button onClick={markAttendance} className='bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-all duration-200' >Submit</button>
      </form>

      <h1 className=' text-md md:text-lg font-semibold mt-[50px] ' >Your Attendance Record</h1>
      <div className=' h-[1px] w-full bg-slate-300 ' ></div>
      <div className="flex items-start justify-center gap-10 mb-[50px] ">
          <AttendanceCalendar attendance={attendanceData} />
          <AttendanceTable attendance={attendanceData} userDataAttendence={userDataAttendence} />
      </div>

      </div>
  )
    }
    </div>
  )
}

export default Attendence