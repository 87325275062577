import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setLoading } from '../../../slices/authSlice';
import { BASE_URL } from '../../../BaseURL';
import { apiConnector } from '../../../services/apiConnector';
import { GrProjects } from "react-icons/gr";
import { FaUser } from "react-icons/fa";
import toast from 'react-hot-toast';
import { PiUserListFill } from "react-icons/pi";
import Loader from '../../../Loading/Loader';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { AiOutlineFileDone } from "react-icons/ai";
import { MdPendingActions } from "react-icons/md";
import { MdOutlineTask } from "react-icons/md";
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';

const Performance = () => {
    const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPresent, setIsPresent] = useState(false);
  const [id, setId] = useState('');
  const [teleData, setTeleData] = useState([]);
  const {loading} = useSelector((state) => state.auth);
  const [task, setTask] = useState('');
  const [currentStatus, setCurrentStatus] = useState('');

  const [leadsData, setLeadsData] = useState([]);

    const getAllLeads = async()=>{
      dispatch(setLoading(true));
        try {
            const response = await apiConnector("get",`${BASE_URL}/upload/importuser`);

            if(!response.data.success){
                throw new Error(response.data.message);
            }

            setLeadsData(response.data.data);

        } catch (error) {
            console.log("Can't fetch Data due to",error);
            toast.error("Leads Cannot fetched");
        }
        dispatch(setLoading(false));
    }


    // get all telecallers
    const getAllTeleCallers = async()=>{
      dispatch(setLoading(true));
      try {
          const response = await apiConnector("get",`${BASE_URL}/telecaller/get-all-telecaller`);

          if(!response.data.success){
              throw new Error(response.data.message);
          }

          setTeleData(response.data.data);

      } catch (error) {
          console.log("Can't fetch Data due to",error);
      }
      dispatch(setLoading(false));
  }

  useEffect(()=>{
    getAllTeleCallers();
    getAllLeads();
  },[])

  const [allTasks, setAllTasks] = useState([]);

//   Get All task
  const getAllDailyTasks = async()=>{
    dispatch(setLoading(true));
    try {
        const response = await apiConnector("get",`${BASE_URL}/task/get-all-tasks`);

        if(!response.data.success){
            throw new Error(response.data.message);
        }

        setAllTasks(response.data.data);

    } catch (error) {
        console.log("Can't fetch Tasks due to",error);
    }
    dispatch(setLoading(false));
}

    useEffect(()=>{
    getAllDailyTasks();
    },[]);

        const completedTasks = allTasks.filter(task => task.currentStatus === "Completed");
        const pendingTasks = allTasks.filter(task => task.currentStatus === "Pending");

        // Step 2: Calculate the total number of tasks
        const totalTasks = allTasks.length;

        // Step 3: Calculate the percentage of completed tasks
        const completedPercentage = (completedTasks.length / totalTasks) * 100;

         // Step 1: Count occurrences of each response type
  const responseCount = leadsData.reduce((acc, item) => {
    acc[item.response] = (acc[item.response] || 0) + 1;
    return acc;
  }, {});

  // Step 2: Calculate total responses
  const totalResponses = leadsData.length;

  // Step 3: Prepare data for the pie chart
  const pieData = [
    { name: 'Interest', value: responseCount['Interest'] || 0 },
    { name: 'Not Interested', value: responseCount['Not Interested'] || 0 },
    { name: 'Not Pick', value: responseCount['Not Pick'] || 0 },
    { name: 'Call Back', value: responseCount['Call Back'] || 0 }
  ];

  // Step 4: Define colors for each response type
  const COLORS = ['#1fd655', '#FF0000', '#FFBB28', '#FF8042'];

  console.log(teleData);


  const calculateUsersDataPercentage = (usersData) => {
    const totalUsers = usersData.length;
    const yesCount = usersData.filter((user) => user.result === "Yes").length;
    return ((yesCount / totalUsers) * 100).toFixed(2); // percentage to two decimal places
  };

  // Function to calculate percentage for completed DailyTasks
  const calculateDailyTaskPercentage = (dailyTasks) => {
    const totalTasks = dailyTasks.length;
    console.log("total tasks", totalTasks);
    const completedTasks = dailyTasks.filter((task) => task.currentStatus === 'Completed').length;
    return ((completedTasks / totalTasks) * 100).toFixed(2); // percentage to two decimal places
  };

  return (
    <>
    {
      loading && (<Loader/>)
    }
    {
        !loading && (
            <div className='flex flex-col gap-[60px]' >
        <div className=' grid grid-cols-1 md:grid-cols-3 gap-[25px] ' >
            <div className=' flex items-center gap-5 ' >
                <p className=' text-4xl text-blue-500 font-bold ' ><GrProjects /></p>
                <div className=' flex flex-col ' >
                    <p className='font-semibold text-gray-600 ' ><span className=' text-gray-800 text-4xl font-bold ' >{allTasks.length}</span> Tasks</p>
                    <p className=' text-[12px] text-gray-500 ' >Assigned</p>
                </div>
            </div>

            <div className=' flex items-center gap-5 ' >
                <p className=' text-4xl text-green-500 font-bold ' ><FaUser /></p>
                <div className=' flex flex-col ' >
                    <p className='font-semibold text-gray-600 ' ><span className=' text-gray-800 text-4xl font-bold ' >{teleData.length}</span> Members</p>
                    <p className=' text-[12px] text-gray-500 ' >Working hard</p>
                </div>
            </div>

            <div className=' flex items-center gap-5 ' >
                <p className=' text-5xl text-orange-500 font-bold ' ><PiUserListFill /></p>
                <div className=' flex flex-col ' >
                    <p className='font-semibold text-gray-600 ' ><span className=' text-gray-800 text-4xl font-bold ' >{leadsData.length}</span> Leads</p>
                    <p className=' text-[12px] text-gray-500 ' >Soon to be cleared</p>
                </div>
            </div>


        </div>

        <div className=' bg-slate-100 rounded-lg flex items-center px-8 py-10 justify-center shadow-2xl border-[1px] border-slate-400 ' >
                <div className='flex flex-col gap-4  ' >
                    <div className=' bg-white px-4 py-2 rounded-lg shadow-xl flex gap-2 items-center ' >
                        <AiOutlineFileDone className=' text-4xl text-green-500 ' />
                        <p className=' font-semibold font-inter ' >
                            <span className=' text-2xl font-bold ' >{completedTasks.length}</span> Completed</p>
                    </div>
                    <div className=' bg-white px-4 py-2 rounded-lg shadow-xl flex gap-2 items-center ' >
                        <MdPendingActions className=' text-4xl text-yellow-500 ' />
                        <p className=' font-semibold font-inter ' ><span className=' text-2xl font-bold ' >{pendingTasks.length}</span> Pending</p>
                    </div>
                    <div className=' bg-white px-4 py-2 rounded-lg shadow-xl flex gap-2 items-center ' >
                        <MdOutlineTask className=' text-4xl text-blue-500 ' />
                        <p className=' font-semibold font-inter ' ><span className=' text-2xl font-bold ' >{allTasks.length}</span> Total Tasks</p>
                    </div>
                </div>
                <div className=' flex flex-col gap-5 mx-auto items-center justify-center ' >
                        <div className=' w-[150px] ' >
                                    <CircularProgressbar 
                                        value={completedPercentage}
                                        text={`${completedPercentage.toFixed(2)}%`}
                                        styles={buildStyles({
                                        textColor: "#4CAF50",
                                        pathColor: "#4CAF50",
                                        trailColor: "#d6d6d6",
                                        textSize: '16px'
                                        })}
                                    />
                        </div>
                        <h3 className=' font-semibold text-xl text-blue-500 ' >Assigned Task Completion</h3>
                        {/* Circular Progress Bar */}
                </div>
        </div>

        <div>

                <div className="p-4">
      <table className="table-auto w-full border border-gray-200">
        <thead>
          <tr className="bg-gray-100">
            <th className="px-4 py-2 border">Full Name</th>
            <th className="px-4 py-2 border">Email</th>
            <th className="px-4 py-2 border">Total Leads</th>
            <th className="px-4 py-2 border">Leads Completion %</th>
            <th className="px-4 py-2 border">Total Tasks</th>
            <th className="px-4 py-2 border">Task Completion %</th>
            {/* <th className="px-4 py-2 border">Attendance</th> */}
          </tr>
        </thead>
        <tbody>
          {/* Loop through each user */}
          {

          teleData.map((user, index) => (
            <tr key={index} className="hover:bg-gray-50 text-sm ">
              <td className="border px-4 py-2">{user.fullName}</td>
              <td className="border px-4 py-2">{user.email}</td>
              <td className="border px-4 py-2">{user.UsersData.length}</td>
              <td className="border px-4 py-2">
                {
                    user.UsersData.length !== 0 && (calculateUsersDataPercentage(user.UsersData))
                }
                {
                    user.UsersData.length === 0 && '-'
                }
              </td>
              <td className="border px-4 py-2">{user.DailyTask.length}</td>
              <td className="border px-4 py-2">
                {
                    user.DailyTask.length !== 0 && (calculateDailyTaskPercentage(user.DailyTask))
                }
                {
                    user.DailyTask.length === 0 && '-'
                }
              </td>
              {/* <td className="border px-4 py-2">{user.Attendence.length} records</td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
        </div>

        

        <div className=' bg-slate-100 rounded-lg flex flex-col items-center px-8 py-10 justify-center shadow-2xl border-[1px] border-slate-400 ' > 
        <h3 className=' font-bold text-2xl mb-[-50px] ' >Leads Data Analysis</h3>
            <div className=' flex items-center justify-center ' >
                <PieChart width={600} height={550}>
                    <Pie
                    data={pieData}
                    cx={300}
                    cy={300}
                    labelLine={false}
                    outerRadius={150}
                    fill="#8884d8"
                    dataKey="value"
                    label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(2)}%`}
                    >
                    {pieData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                </PieChart>
                </div>
        </div>
        
    </div>
        )
    }
    </>
  )
}

export default Performance