import React, { useEffect } from 'react'

import { useState } from 'react'
import userImg from "../../../assests/user1.png";
import { logout } from '../../../services/authAPI.js';
import { VscSignOut } from "react-icons/vsc"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate, useParams } from 'react-router-dom';
import { RxHamburgerMenu } from "react-icons/rx";
import { FaSquareArrowUpRight } from "react-icons/fa6";
import { ImCross } from 'react-icons/im';
import { setLoading } from '../../../slices/authSlice.js';
import { BASE_URL } from '../../../BaseURL.js';
import { apiConnector } from '../../../services/apiConnector.js';
import Loader from '../../../Loading/Loader.jsx';
import { updateUserDetail } from '../../../services/UserAPI.js';
import toast from 'react-hot-toast';
import { IoArrowBackCircleSharp } from 'react-icons/io5';

const UserProfile = () => {
    const { user } = useSelector((state) => state.profile);
    const {loading} = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
      name:"",
      email:"",
      mobile:"",
      services:"",
      result:"",
      response:"",
      source:"",
      followDate:"",
      followResult:"",
      date:"",
      time:"",
    });

    let {id} = useParams();

    const [toggle, setToggle] = useState(true);
    const [userData, setUserData] = useState('');
    const [message, setMessage] = useState('');

    const handleInputChange = (e) =>{
      setFormData({ ...formData,
        [e.target.name]: e.target.value
      });
    }

    const getSingleUser = async() =>{
      dispatch(setLoading(true));
      try {
       
        const response = await apiConnector("get",`${BASE_URL}/user/get-single-user/${id}`);
        
          if(!response.data.success){
              throw new Error(response.data.message);
          }
  
          setUserData(response?.data.data);
          setFormData(response?.data.data);

      } catch (error) {
          console.log("Can't fetch Data due to",error);
      }
      dispatch(setLoading(false));
    }
  
    useEffect(()=>{
      getSingleUser();
    },[])

    const handleUpdate = (e) =>{
      e.preventDefault();

      // if(!formData.response){
      //   toast.error("Response must be");
      //   return;
      // }
         
        dispatch(updateUserDetail(
          id,
          formData.name,
          formData.email,
          formData.mobile,
          formData.services,
          formData.result,
          formData.response,
          formData.source,
          formData.followDate,
          formData.followResult,
          formData.date,
          formData.time,
         navigate));

      //Reset 
      setFormData({
        name:"",
        email:"",
        mobile:"",
        services:"",
        result:"",
        response:"",
        source:"",
        followDate:"",
        followResult:"",
        date:"",
        time:"",
      });

      // window.location.reload();
    }

    const handleMessage = async() =>{
      dispatch(setLoading(true));
      try {
       
        const response = await apiConnector("put",`${BASE_URL}/user/update-message/${id}`,{
          message
        });
        
          if(!response.data.success){
              throw new Error(response.data.message);
          }
  
         setMessage('');
         toast.success('Message Uploaded Successfully');
         window.location.reload();

      } catch (error) {
          console.log("Can't add message due to",error);
          toast.error("Can't Upload Message");
      }
      dispatch(setLoading(false));
    }

    console.log(userData);

  return (
    <div className='w-[100vw] h-[100vh] font-poppins flex flex-col ml-[-60px] overflow-x-hidden ' >
         <div className=' flex lg:items-center flex-col lg:justify-between bg-[#081321] 
       border-b-[black] border-b-[1px] lg:flex-row  lg:py-4 lg:px-5  ' >
                {/* member name */}
                <p className='text-slate-300 font-bold uppercase '  >User Details</p>
                <div className=' flex items-center ' >
                <p className=' text-slate-300 ' >Telecaller :</p>
                <div className='flex items-center gap-1 mt-5 mb-5 lg:mb-0 lg:mt-0 ' >
                    <img src={userImg} loading='lazy' alt='User IMG'  width="50px" />
                    <p className=' text-sm lg:text-md text-slate-300 ' >{user.fullName}</p>
                </div>
                </div>
                <div className='absolute right-0 top-7 mr-4 lg:hidden cursor-pointer z-10 ' >
                  <RxHamburgerMenu className='w-[30px] h-[30px] ' onClick={()=>{setToggle(!toggle)}} />
                </div>
                
       </div>
       {
        loading && (<Loader className="flex items-center justify-center "  />)
      }

       {
        !loading && 
        <div className=' w-full flex ' >
        <div className=' w-[25%] h-[100vh] bg-[#081321] px-5 py-4 text-white flex flex-col border-t-[1px] border-t-slate-200
          gap-3 jusify-center ' >
          <div className=' flex items-center gap-1 text-sm' >
            <p className=' text-sm text-slate-300 ' >Name :</p>
            <p className=' font-semibold ' >{userData?.name}</p>
          </div>
          <div className=' flex items-center gap-1 text-sm ' >
            <p className=' text-md text-slate-300 ' >Email :</p>
            <p className=' font-semibold '>{userData?.email}</p>
          </div>
          <div className=' flex items-center gap-1 text-sm ' >
            <p className=' text-md text-slate-300 ' >Phone :</p>
            <p className=' font-semibold '>{userData?.mobile}</p>
          </div>
          <div className=' flex items-center gap-1 text-sm ' >
            <p className=' text-md text-slate-300 ' >Date :</p>
            <p className=' font-semibold '>{userData?.date}</p>
          </div>
          <div className=' flex items-center gap-1 text-sm ' >
            <p className=' text-md text-slate-300 ' >Time :</p>
            <p className=' font-semibold '>{userData?.time}</p>
          </div>
          {/* <div className=' flex items-center gap-1 text-sm ' >
            <p className=' text-md text-slate-300 ' >Source :</p>
            <p className=' font-semibold '>{userData?.source}</p>
          </div> */}
          <div className=' flex items-center gap-1 text-sm ' >
            <p className=' text-md text-slate-300 ' >Services :</p>
            <p className=' font-semibold '>{userData?.services}</p>
          </div>
          <div className=' flex items-center gap-1 text-sm ' >
            <p className=' text-md text-slate-300 ' >Result :</p>
            <p className=' font-semibold '>{userData?.result}</p>
          </div>
          <div className=' flex items-center gap-1 text-sm ' >
            <p className=' text-md text-slate-300 ' >Next Calling Date :</p>
            <p className=' font-semibold '>{userData?.followDate}</p>
          </div>
          {/* <div className=' flex items-center gap-1 text-sm ' >
            <p className=' text-md text-slate-300 ' >Follow Result :-</p>
            <p className=' font-semibold '>{userData?.followResult}</p>
          </div> */}
          <div className=' w-full  h-[1px] bg-slate-200 ' ></div>
          {/* <div className='flex gap-1 text-sm flex-col ' >
            <p className=' font-bold text-md text-center ' >Messages</p>
            <div className=' text-blue-400 ' >
              {
                userData?.messages?.map((msg,ind)=>(
                  <div key={ind} className='flex gap-1 ' >
                    <p>{ind+1}.</p>
                    <p>{msg}</p>
                  </div>
                ))
              }
            </div>
          </div> */}

        </div>

        {/* right side */}
        <div className=' w-[75%] flex flex-col px-4 py-4 gap-4  ' >
         <div className=' flex justify-between items-center ' >
            <Link to="/dashboard/my-profile" className='flex items-center gap-1 transition-all duration-200 hover:text-blue-500 ' >
        <IoArrowBackCircleSharp className=' text-2xl ' />
        <button>Back</button>
        </Link>

            <div className=' flex justify-end items-center gap-3 ' >
                <p>Current Status :</p>
                {
                  userData?.response === 'Not Interested' && <p className=' text-red-500 
                  bg-red-100 px-4 py-2 rounded-full font-semibold text-sm ' >{userData?.response}</p>
                }
                {
                  userData?.response === 'Interest' && <p className=' text-green-500 
                  bg-emerald-100 px-4 py-2 rounded-full font-semibold text-sm ' >{userData?.response}</p>
                }
                {
                  userData?.response === 'Not Pick' && <p className=' text-yellow-500 
                  bg-yellow-100 px-4 py-2 rounded-full font-semibold text-sm ' >{userData?.response}</p>
                }
                {
                  userData?.response === 'Call Back' && <p className=' text-violet-500 
                  bg-violet-100 px-4 py-2 rounded-full font-semibold text-sm ' >{userData?.response}</p>
                }
              </div>
         </div>
         <div className=' flex items-center gap-3 justify-center ' >
              {/* <input
                              type='text'
                                 id='message'
                                 name='message'
                                 value={message}
                                 onChange={(e)=>setMessage(e.target.value)}
                                 placeholder='Enter your Message'
                                 className=' border-[1px] border-black rounded-md bg-transparent text-[14px] outline-none placeholder:text-[#222c38] px-2 py-2 '
              />
              <button onClick={handleMessage}
                className=' bg-blue-500 text-sm font-semibold text-white px-2 py-2 rounded-md
                 hover:bg-blue-600 transition-all duration-300 ' 
                >Add Message</button> */}
                <h3 className='text-xl font-semibold' >Update User Details</h3>
            </div>
         <div className=' w-full h-[1px] bg-gray-400 ' ></div>

          <div>
          <form>
                <div className='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px] text-[#081321]' >
                <div className='flex bg-blue-200 flex-col gap-1 px-6 py-4 rounded-md ' >
                            <label id='services' className=' text-[16px] font-semibold font-poppins ' >Services</label>
                            <input
                              type='text'
                                 id='services'
                                 name='services'
                                 value={formData.services}
                                 onChange={handleInputChange}
                                 placeholder='Enter Services'
                                 className=' w-[100%] rounded-md bg-transparent text-[14px] outline-none placeholder:text-[#222c38]  '
                            />
                  </div>
                  <div className='flex bg-blue-200 flex-col gap-1 px-6 py-4 rounded-md ' >
                            <label id='result' className=' text-[16px] font-semibold font-poppins ' >Result</label>
                            <input
                                type='text'
                                id='result'
                                name='result'
                                value={formData.result}
                                onChange={handleInputChange}
                                placeholder='Enter Owner Name'
                                className=' w-[100%] rounded-md bg-transparent text-[14px] outline-none placeholder:text-[#222c38]  '
                            />
                  </div>
                {/* <div className='flex bg-blue-200 flex-col gap-1 px-6 py-4 rounded-md ' >
                            <label id='date' className=' text-[16px] font-semibold font-poppins ' >Date</label>
                            <input
                                type='date'
                                id='date'
                                name='date'
                                value={formData.date}
                                onChange={handleInputChange}
                                className=' w-[100%] rounded-md bg-transparent text-[14px] outline-none placeholder:text-[#222c38]  '
                            />
                  </div> */}
                  {/* <div className='flex bg-blue-200 flex-col gap-1 px-6 py-4 rounded-md ' >
                            <label id='time' className=' text-[16px] font-semibold font-poppins ' >Time</label>
                            <input
                                type='text'
                                id='time'
                                name='time'
                                value={formData.time}
                                onChange={handleInputChange}
                                placeholder='Enter Time'
                                className=' w-[100%] rounded-md bg-transparent text-[14px] outline-none placeholder:text-[#222c38]  '
                            />
                  </div> */}
                  <div className='flex bg-blue-200 flex-col gap-1 px-6 py-4 rounded-md ' >
                            <label id='response' className=' text-[16px] font-semibold font-poppins ' >Response</label>
                            <select
                                id='response'
                                name='response'
                                value={formData.response}
                                onChange={handleInputChange}
                                className=' w-[100%] rounded-md bg-transparent text-[14px] outline-none placeholder:text-[#222c38]  '
                            >
                              <option value="" >Select Response</option>
                              <option value="Not Interested" >Not Interested</option>
                              <option value="Interest" >Interest</option>
                              <option value="Call Back" >Call Back</option>
                              <option value="Not Pick" >Not Pick</option>
                            </select>
                  </div>
                  {/* <div className='flex bg-blue-200 flex-col gap-1 px-6 py-4 rounded-md ' >
                            <label id='source' className=' text-[16px] font-semibold font-poppins ' >Source</label>
                            <input
                                type='text'
                                id='source'
                                name='source'
                                value={formData.source}
                                onChange={handleInputChange}
                                placeholder='Enter Source'
                                className=' w-[100%] rounded-md bg-transparent text-[14px] outline-none placeholder:text-[#222c38]  '
                            />
                  </div> */}
                  <div className='flex bg-blue-200 flex-col gap-1 px-6 py-4 rounded-md ' >
                            <label id='followDate' className=' text-[16px] font-semibold font-poppins ' >Next Calling Date</label>
                            <input
                                type='date'
                                id='followDate'
                                name='followDate'
                                value={formData.followDate}
                                onChange={handleInputChange}
                                placeholder='Enter Follow Date'
                                className=' w-[100%] rounded-md bg-transparent text-[14px] outline-none placeholder:text-[#222c38]  '
                            />
                  </div>
                  {/* <div className='flex bg-blue-200 flex-col gap-1 px-6 py-4 rounded-md ' >
                            <label id='followResult' className=' text-[16px] font-semibold font-poppins ' >Follow Result</label>
                            <input
                                type='text'
                                id='followResult'
                                name='followResult'
                                value={formData.followResult}
                                onChange={handleInputChange}
                                placeholder='Enter Follow Result'
                                className=' w-[100%] rounded-md bg-transparent text-[14px] outline-none placeholder:text-[#222c38]  '
                            />
                  </div> */}
                  {/* add message */}
                  <div className=' flex items-center gap-3 bg-blue-200 px-6 rounded-md ' >
                    <input
                                    type='text'
                                      id='message'
                                      name='message'
                                      value={message}
                                      onChange={(e)=>setMessage(e.target.value)}
                                      placeholder='Enter your Message'
                                      className=' border-[1px] border-black rounded-md bg-white text-[14px] outline-none placeholder:text-[#222c38] px-2 py-2 '
                    />
                    <button onClick={handleMessage}
                      className=' bg-blue-500 text-[12px] font-semibold text-white px-2 py-2 rounded-md
                      hover:bg-blue-600 transition-all duration-300 ' 
                      >Add Message</button>
                  </div>
                </div>
                <div className=' flex items-center justify-center ' >
                <button onClick={handleUpdate}
                className=' bg-blue-500 mt-10 text-md font-semibold text-white px-2 py-2 rounded-md
                 hover:bg-blue-600 transition-all duration-300 ' 
                >Update Data</button>
                </div>
            </form>
          </div>

          <div className='flex gap-1 text-md flex-col ' >
            <p className=' font-semibold text-xl ' >Messages</p>
            <div className=' w-full h-[1px] bg-gray-400 mb-2 mt-2 ' ></div>
            <div className=' text-blue-400 ' >
              {
                userData?.messages?.map((msg,ind)=>(
                  <div key={ind} className='flex gap-1 ' >
                    <p>{ind+1}.</p>
                    <p>{msg}</p>
                  </div>
                ))
              }
            </div>
          </div>

        </div>
     </div>
       }

    </div>
  )
}

export default UserProfile