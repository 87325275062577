import React from 'react';

const TaskCompletionStatus = ({ completionPercentage }) => {
  // Tailwind classes for progress bar background based on completionPercentage
  const getBackgroundColor = (percentage) => {
    switch (percentage) {
      case '0':
        return 'bg-red-400';
      case '':
        return 'bg-red-400';
      case '25':
        return 'bg-yellow-400';
      case '50':
        return 'bg-blue-400';
      case '75':
        return 'bg-green-400';
      case '100':
        return 'bg-green-600';
      default:
        return 'bg-gray-300';
    }
  };

  console.log("completion %",getBackgroundColor(completionPercentage));

  return (
    <div className="w-full max-w-lg mx-auto p-4">
      <div className="w-full bg-gray-200 rounded-full h-4">
        <div
          className={`h-4 rounded-full ${getBackgroundColor(completionPercentage)}`}
          style={{ width: completionPercentage }}
        />
      </div>
      <div className="text-right mt-1 text-gray-700">
        {completionPercentage}% completed
      </div>
    </div>
  );
};

export default TaskCompletionStatus;
