import React, { useEffect, useState } from 'react'
import Loader from '../../../Loading/Loader'
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../slices/authSlice';
import { apiConnector } from '../../../services/apiConnector';
import { BASE_URL } from '../../../BaseURL';
import toast from 'react-hot-toast';
import { formateDate } from '../../../utils/formateDate';
import { formateTime } from '../../../utils/formateTime';
import TaskCompletionStatus from './TaskCompletionStatus';

const DailyTaskUser = () => {

  const [id, setId] = useState('');
  const [currentStatus, setCurrentStatus] = useState('');
  const [completionPercentage, setCompletionPercentage] = useState('');
  const {loading} = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [allTasks, setAllTasks] = useState([]);
  const { user } = useSelector((state) => state.profile);
  const Id = user._id


  const handleSubmit = async(e) =>{
    e.preventDefault();
      dispatch(setLoading(true));
      try {
          const response = await apiConnector("put",`${BASE_URL}/task/update-task`,{
            id,
            currentStatus,
            completionPercentage
        });

          if(!response.data.success){
              throw new Error(response.data.message);
          }

          toast.success("Daily Task Updated Succesfully");
          setCurrentStatus('');
          setId('');
          setCompletionPercentage('');

          window.location.reload();

      } catch (error) {
          console.log("Can't update daily task due to",error);
          toast.error(error?.response.data.message);
      }
      dispatch(setLoading(false));

  }



//   Get All task
  const getAllDailyTasks = async()=>{
    dispatch(setLoading(true));
    try {
        const response = await apiConnector("get",`${BASE_URL}/task/get-one-task/${Id}`);

        if(!response.data.success){
            throw new Error(response.data.message);
        }

        setAllTasks(response.data.data);

    } catch (error) {
        console.log("Can't fetch Tasks due to",error);
    }
    dispatch(setLoading(false));
}

    useEffect(()=>{
    getAllDailyTasks();
    },[]);

  return (
    <div className=' w-full h-full flex flex-col gap-2 mt-3 font-poppins ' >
    <div className=' flex items-center justify-between ' >
      <h1 className=' text-md md:text-lg font-semibold ' >Daily Tasks</h1>
    </div>
    <div className=' h-[1px] w-full bg-slate-300 ' ></div>
    {
      loading && (<Loader/>)
    }
    {
      !loading && (
    <div className=' flex flex-col gap-10 ' >
       <form className=' flex flex-col gap-5 ' >
       <div className=' grid grid-cols-3 gap-10 ' > 
            <div className='flex flex-col ' >
                        <label htmlFor={id} >
                        Assigned Tasks List:
                        </label>
                        <select value={id} 
                        className=' w-full rounded-[0.5rem] text-sm outline-none bg-gray-200 p-[12px] pr-10 text-black border-b-2 border-gray-500'
                        onChange={(e) => setId(e.target.value)}>
                        <option value=''>Select Task</option>
                        {allTasks?.DailyTask?.map((tele, index) => (
                            <option key={index} value={tele?._id} >
                            {tele?.task}
                            </option>
                        ))}
                        </select>
                        </div>
                        <div>
                    <label htmlFor='task' >
                        <p className=' text-md text-richblack-5 mb-1 leading-[1.375rem]' >Current Status </p>  </label>
                        <select value={currentStatus} 
                        className=' w-full rounded-[0.5rem] text-sm outline-none bg-gray-200 p-[12px] pr-10 text-black border-b-2 border-gray-500'
                        onChange={(e)=>setCurrentStatus(e.target.value)}>
                        <option value=''>Select</option>
                        <option value='Completed'>Completed</option>
                        <option value='Pending'>Pending</option>
                        </select>
                </div>
               {
                currentStatus === 'Pending' && ( 
                <div>
                    <label htmlFor='completionPercentage' >
                        <p className=' text-md text-richblack-5 mb-1 leading-[1.375rem]' >Completion Status</p>  </label>
                        <select value={completionPercentage} 
                        className=' w-full rounded-[0.5rem] text-sm outline-none bg-gray-200 p-[12px] pr-10 text-black border-b-2 border-gray-500'
                        onChange={(e)=>setCompletionPercentage(e.target.value)}>
                        <option value=''>Select</option>
                        <option value='0'>0%</option>
                        <option value='25'>25%</option>
                        <option value='50'>50%</option>
                        <option value='75'>75%</option>
                        <option value='100'>100%</option>
                        </select>
                </div>)
               }
        </div>
        <button onClick={handleSubmit}
            className=' self-center bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-all duration-200 '
            >Submit</button>
       </form>
       <div className=' flex flex-col gap-5 mb-[50px] ' >
                    <h1 className=' text-md md:text-lg font-semibold ' >All Assigned Tasks</h1>
                    <div className=' h-[1px] w-full bg-slate-300 ' ></div>
                                        <div className="p-4">
                        <table className="table-auto w-full border border-gray-100">
                            <thead>
                            <tr className="bg-gray-100 ">
                                <th className="px-4 py-2 border">S.No</th>
                                <th className="px-4 py-2 border">Task</th>
                                <th className="px-4 py-2 border">Assigned On</th>
                                <th className="px-4 py-2 border">Completion %</th>
                                <th className="px-4 py-2 border">Last Completion Date</th>
                                <th className="px-4 py-2 border">Task Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {/* Loop through each user */}
                            {
                                allTasks?.DailyTask?.map((task, index) => (
                                <tr key={index} className="hover:bg-gray-50 text-sm ">
                                <td className="border px-4 py-2">{index+1}.</td>
                                <td className="border px-4 py-2">{task?.task}</td>
                                <td className="border px-4 py-2">{ formateDate(task?.assignedOn) }</td>
                                <td className="border px-4 py-2">
                                    <TaskCompletionStatus completionPercentage={task?.completionPercentage}/>
                                </td>
                                <td className="border px-4 py-2">{ formateDate(task?.lastSubmissionDate) }</td>
                                <td className={`border px-4 py-2 font-semibold ${task?.currentStatus === 'Completed' ? "text-green-500" : "text-red-500" } `}>{
                                    task?.currentStatus
                                }</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        </div>
                </div>
    </div>
  )
    }
    </div>
  )
}

export default DailyTaskUser