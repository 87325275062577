import React from 'react'
import UploadCSVTelecaller from './UploadCSVTelecaller'
import UploadSingleLead from './UploadSingleLead'

const CreateLead = () => {
  return (
    <div className=' w-full h-full flex flex-col gap-2 mt-3 ' >
            <h1 className=' text-md md:text-lg font-semibold ' >Add New Lead</h1>
            <div className=' h-[1px] w-full bg-slate-300 ' ></div>
            <UploadCSVTelecaller/>
  
            <UploadSingleLead/>
    </div>
  )
}

export default CreateLead