import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../Loading/Loader';
import {formateDate} from "../../../../utils/formateDate";
import {formateTime} from "../../../../utils/formateTime";


const AttendanceTable = ({attendance, userDataAttendence}) => {
    const {loading} = useSelector((state) => state.auth);

  return (
    <div className="flex flex-col pt-2 " >
    <div>
 
     {
       loading && (<Loader/>)
     }
 
     <div className="p-1.5 w-full inline-block align-middle">
     <div className="overflow-hidden border rounded-lg">
       <table className="w-full divide-y divide-gray-200">
         <thead className="bg-blue-500 w-full text-white ">
           <tr>
             <th
               scope="col"
               className="px-4
                py-2 text-[12px] font-bold text-left uppercase "
             >
               Name
             </th>
             <th
               scope="col"
               className="px-4
                py-2 text-[12px] font-bold text-left uppercase "
             >
               Attendance Time
             </th>
             <th
               scope="col"
               className="px-4
                py-2 text-[12px] font-bold text-left uppercase "
             >
               Date
             </th>
             <th
               scope="col"
               className="px-4
                py-2 text-[12px] font-bold text-left uppercase "
             >
               Status
             </th>
           </tr>
         </thead>
         {/* table body */}
         {
             loading && (
                 <div className='w-full' >
                     <Loader/>
                 </div>
             )
         }
         
         {
                attendance?.map((User,index)=>(
                <tbody className="divide-y divide-gray-200" key={index} >
                <tr className='bg-gray-50' >
                        <td className="px-4
                         py-3 text-[12px] text-gray-800 whitespace-nowrap">
                          {userDataAttendence?.fullName}
                        </td>
                        {/* <td className="px-4
                         py-3 text-[12px] text-gray-800 whitespace-nowrap">{userDataAttendence?.email}</td> */}
                        <td className="px-4
                         py-3 text-[12px] text-gray-800 whitespace-nowrap">
                        {
                            formateTime(User?.attendenceTime)
                        }
                        </td>
                        <td className="px-4
                         py-3 text-[12px] text-gray-800 whitespace-nowrap">
                        {
                            formateDate(User?.date)
                        }
                        </td>
                        <td className="px-4
                         py-3 text-[12px]  font-bold whitespace-nowrap ">
                        {
                          User.isPresent === true &&  (<span className='text-green-500' >Present</span>)
                        }
                        {
                          User.isPresent === false &&  (<span className='text-red-500' >Absent</span>)
                        }
                        </td>
                      </tr>
                    </tbody>))
         }
       </table>
     </div>
   </div>

    </div>
  </div>
  )
}

export default AttendanceTable