import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../../BaseURL';
import { useDispatch, useSelector } from 'react-redux';
import { apiConnector } from '../../../services/apiConnector';
import { setLoading } from '../../../slices/authSlice';
import { Link } from 'react-router-dom';
import Loader from "../../../Loading/Loader.jsx";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { IoMdInformationCircle } from "react-icons/io";

const TotalTelecallers = () => {
  const [data, setData] = useState([]);
  const {loading} = useSelector((state) => state.auth);
  const dispatch = useDispatch();

   // get all telecallers
   const getAllTeleCallers = async()=>{
    dispatch(setLoading(true));
    try {
        const response = await apiConnector("get",`${BASE_URL}/telecaller/get-all-telecaller`);

        if(!response.data.success){
            throw new Error(response.data.message);
        }

        setData(response.data.data);
    } catch (error) {
        console.log("Can't fetch Data due to",error);
    }
    dispatch(setLoading(false));
}


  const [filterData, setFiltered] = useState([]);

  const filterTelecallers = () =>{
    const filteredUsers = data.filter(user => user.accountType !== "Admin");
    setFiltered(filteredUsers);
  }

  useEffect(()=>{
    getAllTeleCallers();
  },[]);

  useEffect(()=>{
    filterTelecallers();
  },[data])

  // console.log(filterData);

  return (
    <div className='w-[100vw] h-[100vh] font-poppins flex flex-col gap-10 ml-[-60px] overflow-x-hidden ' >
     <div className=' text-white flex justify-between items-center px-6 py-6 bg-[#081321] ' >
            <h1 className=' text-2xl font-bold uppercase ' >Lead Generation</h1>
            <h4 className=' text-lg bg-indigo-500 px-4 py-2 rounded-md ' >Telecallers</h4>
        </div>
        <Link to="/admin-dashboard/my-profile" className=' w-11/12 mx-auto flex items-center gap-1 mt-[-15px] mb-[-15px] 
        transition-all duration-200 hover:text-blue-500 ' >
        <IoArrowBackCircleSharp className='  text-2xl ' />
        <button>Back</button>
        </Link>
    <div className=' w-11/12 mx-auto ' >
      {
        loading && (<Loader/>)
      }
      

      {
        !loading && (<div className=' w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5' >
          {
        filterData?.map((telecaller,index)=>(
            <div key={index} className="max-w-sm p-5  flex flex-col gap-2
            bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 shadow-xl ">
            {/* <Link > */}
                <h5 className=" text-xl font-semibold tracking-tight text-gray-900 dark:text-white">{telecaller.fullName}</h5>
            {/* </Link> */}
            <p className="text-sm font-normal text-gray-700 dark:text-gray-400">Email :- {telecaller.email}</p>
            <p className="text-sm font-normal text-gray-700 dark:text-gray-400">Phone :- {telecaller.phoneNumber}</p>
            <div className=' flex items-center gap-5 ' >
                <p className="text-sm font-normal text-gray-700 dark:text-gray-400">Total Assigned Users</p>
                <div className=' text-green-800 bg-emerald-200 rounded-full px-4 py-2 ' >{telecaller.UsersData.length}</div>
            </div>

            <Link to={`/telecaller-details/${telecaller._id}`} >
              <button className=" mt-3 px-3 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  Check Data
              </button>
            </Link>
        </div>
        ))
      }
        </div>)
      } 
    </div>
    </div>
  )
}


export default TotalTelecallers