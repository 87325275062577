import React, { useEffect, useState } from 'react'
import UserImg from "../../assests/user.png";
import { Link, useParams } from 'react-router-dom';
import { setLoading } from '../../slices/authSlice';
import { apiConnector } from '../../services/apiConnector';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../BaseURL';
import Loader from '../../Loading/Loader';
import { TbUserSquareRounded } from "react-icons/tb";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { IoMdInformationCircle } from "react-icons/io";

const TelecallerData = () => {

    const {id} = useParams();
    
    const [data, setData] = useState([]);
    const [teleLeadsData, setTeleLeads] = useState([]);
    const {loading} = useSelector((state) => state.auth);
    const dispatch = useDispatch();
  
     // get all telecallers
     const getTelecaller = async()=>{
      dispatch(setLoading(true));
      try {
          const response = await apiConnector("get",`${BASE_URL}/telecaller/get-one-telecaller/${id}`);
  
          if(!response.data.success){
              throw new Error(response.data.message);
          }
  
          setData(response.data.data);
          setTeleLeads(response.data.data.UsersData);
      } catch (error) {
          console.log("Can't fetch Data due to",error);
      }
      dispatch(setLoading(false));
  }

  useEffect(()=>{
    getTelecaller();
  },[]);

  return (
    <div className='w-[100vw] h-[100vh] font-poppins flex flex-col gap-10 ml-[-60px] overflow-x-hidden ' >
        <div className=' text-white flex justify-between items-center px-6 py-6 bg-[#081321] ' >
            <h1 className=' text-2xl font-bold uppercase ' >Lead Generation</h1>
            <h4 className=' text-lg ' >Telecaller Details</h4>
        </div>
        <Link to="/admin-dashboard/my-profile" className=' w-11/12 mx-auto flex items-center gap-1 mt-[-15px] mb-[-15px] 
        transition-all duration-200 hover:text-blue-500 ' >
        <IoArrowBackCircleSharp className='  text-2xl ' />
        <button>Back</button>
        </Link>
        {
            loading && (
                <div className=' text-white ' >
                    <Loader/>
                </div>
            )
        }
      {
        !loading  && (  
        <div className=' w-11/12 mx-auto flex justify-between gap-[50px] mb-10 ' >
            <div className=' flex flex-col gap-10 ' >
                <div className=' w-[250px] bg-white shadow-xl border-2 rounded-lg px-5 py-8 flex flex-col gap-2 justify-center items-center ' >
                <p className=' font-semibold text-[80px] text-blue-500 mb-3 ' ><TbUserSquareRounded/></p>
                <p className=' text-xl font-semibold ' >{data?.fullName}</p>
                <div className=' flex flex-col items-center justify-center mt-[-5px] ' >
                <p className=' text-slate-600 ' >Telecaller</p>
                <p className=' text-blue-700 text-sm ' >Lead Generation - Zupinn</p>
                </div>
                </div>
                <div className=' bg-white shadow-xl border-2 rounded-lg px-5 py-8 flex flex-col gap-2 ' >
                    <p className=' text-xl font-semibold ' >About</p>
                    <div className=' flex flex-col' >
                    <p className=' text-slate-600 ' >Email</p>
                    <p className=' text-blue-700 text-sm ' >{data?.email}</p>
                    </div>
                    <div className=' flex flex-col' >
                    <p className=' text-slate-600 ' >Phone</p>
                    <p className=' text-blue-700 text-sm ' >{data?.phoneNumber}</p>
                    </div>
                    <div className=' flex flex-col' >
                    <p className=' text-slate-600 ' >Total Assigned Leads</p>
                    <p className=' text-blue-700 text-sm ' >{teleLeadsData?.length}</p>
                    </div>
                </div>
            </div>
            {
                teleLeadsData.length === 0 && (<div className='w-9/12 ' >
                    <p className=' py-3 text-center flex items-center gap-1 justify-center bg-blue-200 text-blue-700 
                    rounded-md ' ><IoMdInformationCircle/> No Data is assigned currently</p>
                </div>)
            }
            {
                teleLeadsData.length > 0 && (
                <div className=" w-9/12 flex flex-col gap-5 " >
                {/* <div className=' pr-3 w-full align-middle flex justify-end ' >
                     <div className='flex gap-10 ' >
                   <label  >
                     Filter by vNumber:
                     <select value={selectedVNumber} 
                     className=' ml-3 px-2 py-1 md:px-4 md:py-2 text-sm  rounded-md md:text-md shadow-2xl border-slate-400 border-[1px] outline-none cursor-pointer '
                     onChange={(e) => setSelectedVNumber(e.target.value)}>
                       <option value=''>All</option>
                       {uniqueVNumbers.map((vNumber, index) => (
                         <option key={index} value={vNumber}>
                           {vNumber}
                         </option>
                       ))}
                     </select>
                   </label>
                   <label >
                     Filter by ownerName:
                     <select value={selectedOwnerName} 
                     className=' ml-3 px-2 py-1 md:px-4 md:py-2 text-sm  rounded-md md:text-md shadow-2xl border-slate-400 border-[1px] outline-none cursor-pointer '
                     onChange={(e) => setSelectedOwnerName(e.target.value)}>
                       <option value=''>All</option>
                       {uniqueOwnerNames.map((ownerName, index) => (
                         <option key={index} value={ownerName}>
                           {ownerName}
                         </option>
                       ))}
                     </select>
                   </label>
                 </div>
                </div> */}
                 <div className="p-1.5 w-full inline-block align-middle">
                   <div className="overflow-hidden border rounded-lg">
                     <table className="w-full divide-y divide-gray-200">
                       <thead className="bg-blue-500 w-full text-white ">
                         <tr>
                           <th
                             scope="col"
                             className="px-4
                              py-2 text-[12px] font-bold text-left uppercase "
                           >
                             Name
                           </th>
                           <th
                             scope="col"
                             className="px-4
                              py-2 text-[12px] font-bold text-left uppercase "
                           >
                             Email
                           </th>
                           <th
                             scope="col"
                             className="px-4
                              py-2 text-[12px] font-bold text-left uppercase "
                           >
                             Mobile
                           </th>
                           <th
                             scope="col"
                             className="px-4
                              py-2 text-[12px] font-bold text-left uppercase "
                           >
                             Source
                           </th>
                           <th
                             scope="col"
                             className="px-4
                              py-2 text-[12px] font-bold text-left uppercase "
                           >
                             Services
                           </th>
                           <th
                             scope="col"
                             className="px-4
                              py-2 text-[12px] font-bold text-left uppercase "
                           >
                             Date
                           </th>
                           <th
                             scope="col"
                             className="px-4
                              py-2 text-[12px] font-bold text-left uppercase "
                           >
                             Time
                           </th>
                           <th
                             scope="col"
                             className="px-4
                              py-2 text-[12px] font-bold text-left uppercase "
                           >
                             Next Calling Date
                           </th>
                           {/* <th
                             scope="col"
                             className="px-4
                              py-2 text-[12px] font-bold text-left uppercase "
                           >
                             Follow Up Result
                           </th> */}
                           <th
                             scope="col"
                             className="px-4
                              py-2 text-[12px] font-bold text-left uppercase "
                           >
                             Result
                           </th>
                           {/* <th
                             scope="col"
                             className="px-4
                              py-2 text-[12px] font-bold text-left uppercase "
                           >
                             Assigned To
                           </th> */}
                           <th
                             scope="col"
                             className="px-4
                              py-2 text-[12px] font-bold text-left uppercase "
                           >
                             Response
                           </th>
                         </tr>
                       </thead>
                       {/* table body */}
                       {
                           loading && (
                               <div className='w-full' >
                                   <Loader/>
                               </div>
                           )
                       }
                       {
                           !loading && (
                            teleLeadsData?.map((User,index)=>(
                               <tbody className="divide-y divide-gray-200" key={index} >
                   <tr className='bg-gray-50' >
            <td className="px-4
             py-3 text-[12px] text-gray-800 whitespace-nowrap">
              {User?.name}
            </td>
            <td className="px-4
             py-3 text-[12px] text-gray-800 whitespace-nowrap">{User?.email}</td>
            <td className="px-4
             py-3 text-[12px] text-gray-800 whitespace-nowrap">
            {User?.mobile}
            </td>
            <td className="px-4
             py-3 text-[12px] text-gray-800 whitespace-nowrap">
            {
            User?.source && (User?.source)
            }
            {
            !User?.source && "-"
            }
            </td>
            <td className="px-4
             py-3 text-[12px] text-gray-800 whitespace-nowrap">
            {User?.services && (User?.services)
            }
            {
            !User?.services && "-"
            }
            </td>
            <td className="px-4
             py-3 text-[12px] text-gray-800 whitespace-nowrap">
            {User?.date && (User?.date)
            }
            {
            !User?.date && "-"
            }
            </td>
            <td className="px-4
             py-3 text-[12px] text-gray-800 whitespace-nowrap">
            {User?.time}
            </td>
            <td className="px-4
             py-3 text-[12px] text-gray-800 whitespace-nowrap">
            {
            User?.followDate && (User?.followDate)
            }
            {
            !User?.followDate && "-"
            }
            </td>
            <td className="px-4
             py-3 text-[12px] text-gray-800 whitespace-nowrap">
            {
            User?.followResult && (User?.followResult)
            }
            {
            !User?.followResult && "-"
            }
            </td>
            {
             !User?.response
              && (<td className="px-4
             py-3 text-[12px] text-gray-800 whitespace-nowrap ">
            {"-"}
            </td>)
            }
            {
             User?.response === 'Interest'
              && (<td className="px-4
             py-3 text-[12px] text-green-500 font-bold whitespace-nowrap ">
            {
              User?.response && (User?.response)
            }
            </td>)
            }
            {
             User?.response === 'Not Pick'
              && (<td className="px-4
             py-3 text-[12px] text-yellow-500 font-bold whitespace-nowrap ">
            {User?.response}
            </td>)
            }
            {
             User?.response === 'Call Back'
              && (<td className="px-4
             py-3 text-[12px] text-violet-500 font-bold whitespace-nowrap ">
            {User?.response}
           
            </td>)
            }
            {
             User?.response === 'Not Interested'
              && (<td className="px-4
             py-3 text-[12px] text-red-500 font-bold whitespace-nowrap ">
            {User?.response}
            </td>)
            }
          </tr>
                       </tbody>
                           ))
                           )
                       }
                     </table>
                   </div>
                 </div>
                        </div>)
            }
        </div>
    )
      }
    </div>
  )
}

export default TelecallerData