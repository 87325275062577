import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createsingleLead } from '../../../services/UserAPI';
import toast from 'react-hot-toast';

const UploadSingleLead = () => {
    const { user } = useSelector((state) => state.profile);
    const {loading} = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const id = user._id;

    const [formData, setFormData] = useState({
      Name:"",
      Email:"",
      Mobile:"",
      Services:"",
      resultUser:"",
      Response:"",
      Source:"",
      FollowDate:"",
      FollowResult:"",
    });

    const handleInputChange = (e) =>{
        setFormData({ ...formData,
          [e.target.name]: e.target.value
        });
      }

      const handleUpdate = (e) =>{
         e.preventDefault();

      if(!formData.Name || !formData.Email || !formData.Mobile){
        toast.error("Fill the Mandatory Fields");
        return;
      }
         
        dispatch(createsingleLead(
          id,
          formData.Name,
          formData.Email,
          formData.Mobile,
          formData.Services,
          formData.resultUser,
          formData.Response,
          formData.source,
          formData.FollowDate,
          formData.FollowResult,
         navigate));

      //Reset 
      setFormData({
        name:"",
        email:"",
        mobile:"",
        services:"",
        result:"",
        response:"",
        source:"",
        followDate:"",
        followResult:"",
        date:"",
        time:"",
      });
      }

  return (
    <div className=' w-full mt-[40px] flex flex-col gap-5 mb-[100px] ' >
         <h1 className=' text-md md:text-lg font-semibold ' >Upload Single Lead</h1>
         <div className=' bg-slate-300 h-[1px] w-full ' ></div>
         <form>
            <div className=' grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px] text-[#081321] ' >
                            <div>
                                <label htmlFor='Email' >
                                    <p className=' text-md text-richblack-5 mb-1 leading-[1.375rem]' >Email Address <sup className='text-[#FF0000] ' >*</sup> </p>  </label>
                                    <input
                                        required
                                        type='email'
                                        id='Email'
                                        name='Email'
                                        placeholder='Enter Email'
                                        value={formData.Email}
                                        onChange={handleInputChange}
                                        className="w-full rounded-[0.5rem] text-sm outline-none bg-gray-200 p-[12px] pr-10 text-black border-b-2 border-gray-500"
                                    />
                            </div>
                            <div>
                                <label htmlFor='Name' >
                                    <p className=' text-md text-richblack-5 mb-1 leading-[1.375rem]' >Name <sup className='text-[#FF0000] ' >*</sup> </p>  </label>
                                    <input
                                        required
                                        type='text'
                                        id='Name'
                                        name='Name'
                                        placeholder='Enter Name'
                                        value={formData.Name}
                                        onChange={handleInputChange}
                                        className="w-full rounded-[0.5rem] text-sm outline-none bg-gray-200 p-[12px] pr-10 text-black border-b-2 border-gray-500"
                                    />
                            </div>
                            <div>
                                <label htmlFor='Mobile' >
                                    <p className=' text-md text-richblack-5 mb-1 leading-[1.375rem]' >Mobile <sup className='text-[#FF0000] ' >*</sup> </p>  </label>
                                    <input
                                        required
                                        type='text'
                                        id='Mobile'
                                        name='Mobile'
                                        placeholder='Enter Mobile'
                                        value={formData.Mobile}
                                        onChange={handleInputChange}
                                        className="w-full rounded-[0.5rem] text-sm outline-none bg-gray-200 p-[12px] pr-10 text-black border-b-2 border-gray-500"
                                    />
                            </div>
                            <div>
                                <label htmlFor='Services' >
                                    <p className=' text-md text-richblack-5 mb-1 leading-[1.375rem]' >Services <sup className='text-[#FF0000] ' ></sup> </p>  </label>
                                    <input
                                        required
                                        type='text'
                                        id='Services'
                                        name='Services'
                                        placeholder='Enter Services'
                                        value={formData.Services}
                                        onChange={handleInputChange}
                                        className="w-full rounded-[0.5rem] text-sm outline-none bg-gray-200 p-[12px] pr-10 text-black border-b-2 border-gray-500"
                                    />
                            </div>
                            <div>
                                <label htmlFor='resultUser' >
                                    <p className=' text-md text-richblack-5 mb-1 leading-[1.375rem]' >Result <sup className='text-[#FF0000] ' ></sup> </p>  </label>
                                    <input
                                        required
                                        type='text'
                                        id='resultUser'
                                        name='resultUser'
                                        placeholder='Enter Result'
                                        value={formData.resultUser}
                                        onChange={handleInputChange}
                                        className="w-full rounded-[0.5rem] text-sm outline-none bg-gray-200 p-[12px] pr-10 text-black border-b-2 border-gray-500"
                                    />
                            </div>
                            <div>
                                <label htmlFor='Response' >
                                    <p className=' text-md text-richblack-5 mb-1 leading-[1.375rem]' >Response <sup className='text-[#FF0000] ' ></sup> </p>  </label>
                                    <input
                                        required
                                        type='text'
                                        id='Response'
                                        name='Response'
                                        placeholder='Enter Response'
                                        value={formData.Response}
                                        onChange={handleInputChange}
                                        className="w-full rounded-[0.5rem] text-sm outline-none bg-gray-200 p-[12px] pr-10 text-black border-b-2 border-gray-500"
                                    />
                            </div>
                            <div>
                                <label htmlFor='Source' >
                                    <p className=' text-md text-richblack-5 mb-1 leading-[1.375rem]' >Source <sup className='text-[#FF0000] ' ></sup> </p>  </label>
                                    <input
                                        required
                                        type='text'
                                        id='Source'
                                        name='Source'
                                        placeholder='Enter Source'
                                        value={formData.Source}
                                        onChange={handleInputChange}
                                        className="w-full rounded-[0.5rem] text-sm outline-none bg-gray-200 p-[12px] pr-10 text-black border-b-2 border-gray-500"
                                    />
                            </div>
                            <div>
                                <label htmlFor='FollowResult' >
                                    <p className=' text-md text-richblack-5 mb-1 leading-[1.375rem]' >Follow Result <sup className='text-[#FF0000] ' ></sup> </p>  </label>
                                    <input
                                        required
                                        type='text'
                                        id='FollowResult'
                                        name='FollowResult'
                                        placeholder='Enter Follow Result'
                                        value={formData.FollowResult}
                                        onChange={handleInputChange}
                                        className="w-full rounded-[0.5rem] text-sm outline-none bg-gray-200 p-[12px] pr-10 text-black border-b-2 border-gray-500"
                                    />
                            </div>
                            <div>
                                <label htmlFor='FollowDate' >
                                    <p className=' text-md text-richblack-5 mb-1 leading-[1.375rem]' >Follow Date <sup className='text-[#FF0000] ' ></sup> </p>  </label>
                                    <input
                                        required
                                        type='text'
                                        id='FollowDate'
                                        name='FollowDate'
                                        placeholder='Enter Follow Date'
                                        value={formData.FollowDate}
                                        onChange={handleInputChange}
                                        className="w-full rounded-[0.5rem] text-sm outline-none bg-gray-200 p-[12px] pr-10 text-black border-b-2 border-gray-500"
                                    />
                            </div>
            </div>
            <div className=' flex items-center justify-center ' >
                <button onClick={handleUpdate}
                className=' bg-blue-500 mt-10 text-md font-semibold text-white px-2 py-2 rounded-md
                 hover:bg-blue-600 transition-all duration-300 ' 
                >Add Lead Data</button>
                </div>
         </form>
    </div>
  )
}

export default UploadSingleLead