import React, { useState } from 'react'
import userImg from "../../assests/user1.png";
import Rbm from "./RbmComponent/Rbm.jsx";
import Sale from "./SaleComponent/Sale.jsx";
import PlantExpense from "./PlantExpensesComponents/PlantExpense.jsx";
import { logout } from '../../services/authAPI.js';
import { VscSignOut } from "react-icons/vsc"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from 'react-router-dom';
import { RxHamburgerMenu } from "react-icons/rx";
import { FaSquareArrowUpRight } from "react-icons/fa6";
import { ImCross } from 'react-icons/im';
import SaleTable from './SaleComponent/SaleTable.jsx';
import TodayData from './TodayData/TodayData.jsx';
import AllDataTable from './AllData/AllDataTable.jsx';
import AllData from './AllData/AllData.jsx';
import Attendence from './Attendence/Attendence.jsx';
import CreateLead from './AddLead/CreateLead.jsx';
import DailyTaskUser from './DailyTask/DailyTaskUser.jsx';

const Home = () => {
    const { user } = useSelector((state) => state.profile)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [toggle, setToggle] = useState(true);
    const [menu, setMenu] = useState('addLead');
  
    const changeClick = (menuType) =>{
      setMenu(menuType);
    }
  return (
    <div className='w-full relative h-[100vh] font-poppins flex ' >
       <div className=' w-full flex gap-5 ' >
       <div className={`${toggle === true ? "left-0 fixed " : "left-[-60%] absolute "} top-0 w-[200px] z-20 
       md:w-[20%] h-full bg-[#081321] md:fixed px-3 py-4 border-r-[2px] border-r-black font-poppins text-gray-50 transition-all duration-300 `} >
        <p className=' text-[16px] md:text-[18px] text-center mb-8 text-blue-500 ' >LEAD GENERATION</p>
        <div className='ml-4 flex flex-col text-[12px] gap-2 md:text-sm ' >
          {/* <p className={`${menu === 'dashboard' ? " bg-slate-400" : "transition-all duration-200 hover:bg-slate-200 hover:text-black "} cursor-pointer px-3 py-2 rounded-md
          `} onClick={()=> changeClick('dashboard')} >User Dashboard</p> */}
          <p className={`${menu === 'addLead' ? " bg-slate-400" : "transition-all duration-200 hover:bg-slate-200 hover:text-black"} cursor-pointer px-3 py-2 rounded-md`} 
           onClick={()=> changeClick('addLead')} >Add Lead</p>
          <p className={`${menu === 'todayData' ? " bg-slate-400" : "transition-all duration-200 hover:bg-slate-200 hover:text-black"} cursor-pointer px-3 py-2 rounded-md`} 
           onClick={()=> changeClick('todayData')} >Today's Assigned Leads</p>
           <p className={`${menu === 'allusers' ? " bg-slate-400" : "transition-all duration-200 hover:bg-slate-200 hover:text-black"} cursor-pointer px-3 py-2 rounded-md`} 
           onClick={()=> changeClick('allusers')} >All Leads</p>
           <p className={`${menu === 'dailyTask' ? " bg-slate-400" : "transition-all duration-200 hover:bg-slate-200 hover:text-black"} cursor-pointer px-3 py-2 rounded-md`} 
           onClick={()=> changeClick('dailyTask')} >Daily Task</p>
           <p className={`${menu === 'attendence' ? " bg-slate-400" : "transition-all duration-200 hover:bg-slate-200 hover:text-black"} cursor-pointer px-3 py-2 rounded-md`} 
           onClick={()=> changeClick('attendence')} >Attendence</p>
           <div
                          onClick={() => {
                            dispatch(logout(navigate))
                          }}
                          className=" flex items-center
                          cursor-pointer gap-x-1 px-3 py-2 text-sm md:text-md text-red-500 hover:bg-richblack-700 hover:text-richblack-25
                          transition-all duration-200 hover:bg-red-500 hover:text-white rounded-md
                          "
                        >
                          <VscSignOut className="text-md" />
                          Logout
            </div>
        </div>
      </div>
      {
        toggle && (<ImCross className={`${toggle === true ? "left-[43%]" : "left-[2%]"} w-[30px] h-[30px] rounded-md px-1 py-1
        bg-red-500 text-white
       block fixed md:hidden top-[16%] text-md cursor-pointer transition-all duration-300`} onClick={()=>setToggle(!toggle)} />)
      }
      {
        !toggle &&  (<RxHamburgerMenu className={`${toggle === true ? "left-[43%]" : "left-[2%]"} w-[30px] h-[30px] rounded-md px-1 py-1
        bg-blue-500 text-white
       block fixed md:hidden top-[16%] text-md cursor-pointer transition-all duration-300`} onClick={()=>setToggle(!toggle)} />)
      }

<div className=' absolute w-[100%] flex flex-col ' >
       <div className=' flex lg:items-center flex-col lg:justify-end bg-[#081321] 
       border-b-[black] border-b-[1px] lg:flex-row  lg:py-4 lg:px-5  ' >
                {/* member name */}
                <p className=' text-slate-300 ' >Telecaller :-</p>
                <div className='flex items-center gap-1 mt-5 mb-5 lg:mb-0 lg:mt-0 ' >
                    <img src={userImg} loading='lazy' alt='User IMG'  width="50px" />
                    <p className=' text-sm lg:text-md text-slate-300 ' >{user.fullName}</p>
                </div>
                <div className='absolute right-0 top-7 mr-4 lg:hidden cursor-pointer z-10 ' >
                  <RxHamburgerMenu className='w-[30px] h-[30px] ' onClick={()=>{setToggle(!toggle)}} />
                </div>
                
       </div>

       <div className=' w-[78%] ml-[290px] flex flex-col gap-5 ' >
       {
          menu === 'addLead' && (<CreateLead/>)
        }
        
        {
          menu === 'todayData' && (<TodayData/>)
        }
        
        {
          menu === 'allusers' && (<AllData/>)
        }

        {
          menu === 'attendence' && (<Attendence/>)
        }

{
          menu === 'dailyTask' && (<DailyTaskUser/>)
        }
       
       </div>

       </div>

      
       </div>

    </div>
  )
}

export default Home