import React, { useEffect, useState } from 'react'
import { HiMiniUserGroup } from "react-icons/hi2";
import { FaUserCheck } from "react-icons/fa6";
import { TbUserShare } from "react-icons/tb";
import { FaUsersSlash } from "react-icons/fa";
import { FiUserX } from "react-icons/fi";
import { PiUserCircleFill } from "react-icons/pi";
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../../BaseURL';
import { apiConnector } from '../../../services/apiConnector';
import { setLoading } from '../../../slices/authSlice';
import toast from 'react-hot-toast';
import Loader from '../../../Loading/Loader';
import DataShow from './DataShow';
import { Link } from 'react-router-dom';


const AdminDash = () => {
  const [leadsData, setLeadsData] = useState([]);
  const {loading} = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const getAllLeads = async()=>{
      dispatch(setLoading(true));
        try {
            const response = await apiConnector("get",`${BASE_URL}/upload/importuser`);

            if(!response.data.success){
                throw new Error(response.data.message);
            }

            setLeadsData(response.data.data);

        } catch (error) {
            console.log("Can't fetch Data due to",error);
            toast.error("Leads Cannot fetched");
        }
        dispatch(setLoading(false));
    }

    useEffect(()=>{
      getAllLeads();
    },[])

    const InterestedData = leadsData.filter(item => item.response==="Interest");
    const NotInterestedData = leadsData.filter(item => item.response==="Not Interested");
    const NotPickData = leadsData.filter(item => item.response==="Not Pick");
    const CallBackData = leadsData.filter(item => item.response==="Call Back");

    const [Teledata, setTeleData] = useState([]);

    // get all telecallers
   const getAllTeleCallers = async()=>{
    dispatch(setLoading(true));
    try {
        const response = await apiConnector("get",`${BASE_URL}/telecaller/get-all-telecaller`);

        if(!response.data.success){
            throw new Error(response.data.message);
        }

        setTeleData(response.data.data);
    } catch (error) {
        console.log("Can't fetch Data due to",error);
    }
    dispatch(setLoading(false));
}


  const [filteredTeleData, setFiltered] = useState([]);

  const filterTelecallers = () =>{
    const filteredUsers = Teledata.filter(user => user.accountType !== "Admin");
    setFiltered(filteredUsers);
  }

  useEffect(()=>{
    getAllTeleCallers();
  },[]);

  useEffect(()=>{
    filterTelecallers();
  },[Teledata])

  return (
    <>
    {
      loading && (<Loader/>)
    }
    {
      !loading && (<div className='w-[97%] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 font-poppins ' >
        <Link to="/total-leads" >
        <div className='flex flex-col items-center gap-3 cursor-pointer h-[200px] bg-white border-slate-400 border-r-[1px] border-b-[1px] px-7 py-6 ' >
        <p className=' font-semibold text-[70px] text-blue-500 ' >
          <HiMiniUserGroup/>
          </p>
          <div className=' flex flex-col items-center ' >
            <p className=' font-bold text-4xl ' >{leadsData?.length}</p>
            <p className=' text-md mt-[-2px] ' >Total Leads</p>
          </div>
      </div>
        </Link>
      <Link to="/interested-leads" >
      <div className='flex flex-col items-center 
      gap-3 cursor-pointer h-[200px] bg-white border-slate-400 border-r-[1px] border-b-[1px] px-7 py-6 ' >
        <p className=' font-semibold text-[70px] text-green-500 ' >
          <FaUserCheck/>
          </p>
          <div className=' flex flex-col items-center ' >
            <p className=' font-bold text-4xl ' >{InterestedData?.length}</p>
            <p className=' text-md mt-[-2px] ' >Interested Leads</p>
          </div>
      </div>
      </Link>
      <Link to="/callback-leads" >
      <div className='flex flex-col items-center 
      gap-3 cursor-pointer h-[200px] bg-white border-slate-400 border-b-[1px] px-7 py-6 ' >
        <p className=' font-semibold text-[70px] text-yellow-500 ' >
          <TbUserShare/>
          </p>
          <div className=' flex flex-col items-center ' >
            <p className=' font-bold text-4xl ' >{CallBackData?.length}</p>
            <p className=' text-md mt-[-2px] ' >Call Back Leads</p>
          </div>
      </div>
      </Link>
      <Link to="/not-pick-leads" >
      <div className='flex flex-col items-center 
      gap-3 cursor-pointer h-[200px] bg-white border-slate-400 px-7 py-6 border-r-[1px] ' >
        <p className=' font-semibold text-[70px] text-orange-500 ' >
          <FiUserX/>
          </p>
          <div className=' flex flex-col items-center ' >
            <p className=' font-bold text-4xl ' >{NotPickData?.length}</p>
            <p className=' text-md mt-[-2px] ' >Not Pick Leads</p>
          </div>
      </div>
      </Link>
      <Link to="/not-interested-leads" >
      <div className='flex flex-col items-center gap-3 cursor-pointer h-[200px]
       bg-white border-slate-400 border-r-[1px] px-7 py-6 ' >
        <p className=' font-semibold text-[70px] text-red-500 ' >
          <FaUsersSlash/>
          </p>
          <div className=' flex flex-col items-center ' >
            <p className=' font-bold text-4xl ' >{NotInterestedData?.length}</p>
            <p className=' text-md mt-[-2px] ' >Not Interested Leads</p>
          </div>
      </div>
      </Link>
      <Link to="/total-telecallers-list" >
      <div className='flex flex-col items-center gap-3 cursor-pointer h-[200px]
       bg-white border-slate-400 px-7 py-6 ' >
        <p className=' font-semibold text-[70px] text-violet-500 ' >
          <PiUserCircleFill/>
          </p>
          <div className=' flex flex-col items-center ' >
            <p className=' font-bold text-4xl ' >{filteredTeleData?.length}</p>
            <p className=' text-md mt-[-2px] ' >Total Telecallers</p>
          </div>
      </div>
      </Link>
  </div>)
    }
    </>
  )
}

export default AdminDash