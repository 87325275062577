import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../BaseURL';
import { apiConnector } from '../../../services/apiConnector';
import { setLoading } from '../../../slices/authSlice';
import toast from 'react-hot-toast';
import Loader from '../../../Loading/Loader';

const AdminAttendance = () => {
    const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPresent, setIsPresent] = useState(false);
  const [id, setId] = useState('');
  const [teleData, setTeleData] = useState([]);
  const {loading} = useSelector((state) => state.auth);

  const handleSubmit = async(e) =>{
    e.preventDefault();
      dispatch(setLoading(true));
      try {
          const response = await apiConnector("post",`${BASE_URL}/attendence/add-attendence`,{
            id,isPresent});

          if(!response.data.success){
              throw new Error(response.data.message);
          }

          toast.success("Attendance Marked Succesfully");

      } catch (error) {
          console.log("Can't mark attendace due to",error);
          toast.error(error?.response.data.message);
      }
      dispatch(setLoading(false));

  }

    const handlePresent = (e) => {
        e.preventDefault();
        setIsPresent(!isPresent);
    };


    // get all telecallers
    const getAllTeleCallers = async()=>{
      dispatch(setLoading(true));
      try {
          const response = await apiConnector("get",`${BASE_URL}/telecaller/get-all-telecaller`);

          if(!response.data.success){
              throw new Error(response.data.message);
          }

          setTeleData(response.data.data);

      } catch (error) {
          console.log("Can't fetch Data due to",error);
      }
      dispatch(setLoading(false));
  }

  useEffect(()=>{
    getAllTeleCallers();
  },[])

  return (
    <div>
         {
      loading && (<Loader/>)
    }
        {
            !loading && (<form className=' flex flex-col gap-10 items-center justify-center ' >
            <div className='flex items-center justify-center gap-[50px]' >
                    <div className='flex gap-10 ' >
                    <label  >
                    Telecallers List:
                    <select value={id} 
                    className=' ml-3 px-1 py-1 text-sm  rounded-md md:text-md shadow-2xl border-slate-400 border-[1px] outline-none cursor-pointer '
                    onChange={(e) => setId(e.target.value)}>
                    <option value=''>Select Telecaller</option>
                    {teleData?.map((tele, index) => (
                        <option key={index} value={tele?._id} >
                        {tele?.fullName}
                        </option>
                    ))}
                    </select>
                </label>
                    </div>
                    <div className="flex p-1 gap-x-1 my-2 rounded-full max-w-max bg-[#081321] ">
                            <button
                                    className={`${isPresent === true
                                    ?"bg-green-500 text-white" 
                                    : "bg-transparent text-gray-400 hover:bg-green-500 hover:text-white "}
                                    py-2 px-5 rounded-full transition-all duration-200 `}
                                    onClick={handlePresent}
                                >
                                    Present
                            </button>
                            <button
                                className={`${isPresent === false
                                ? "bg-red-500 text-white"
                                : "bg-transparent text-gray-400 hover:bg-red-500 hover:text-white "} 
                                py-2 px-5 rounded-full transition-all duration-200`}
                                onClick={handlePresent}
                            >
                                Absent
                            </button>
                    </div>
            </div>
    <button onClick={handleSubmit}
     className=' bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-all duration-200 '
    >Mark Attendance</button>
  </form>)
        }
      </div>
  )
}

export default AdminAttendance