import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setLoading } from '../../slices/authSlice';
import { apiConnector } from '../../services/apiConnector';
import { BASE_URL } from '../../BaseURL';
import toast from 'react-hot-toast';
import Loader from '../../Loading/Loader';
import { formateDate } from '../../utils/formateDate';
import { formateTime } from '../../utils/formateTime';
import TaskCompletionStatus from '../../pages/Dashboard/DailyTask/TaskCompletionStatus';

const DailyTask = () => {
    const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPresent, setIsPresent] = useState(false);
  const [id, setId] = useState('');
  const [teleData, setTeleData] = useState([]);
  const {loading} = useSelector((state) => state.auth);
  const [task, setTask] = useState('');
  const [lastSubmissionDate, setLastSubmissionDate] = useState('');

// const handleInputChange = (e) =>{
//     setFormData({...formData,
//         [e.target.name]: e.target.value
// })}

  const handleSubmit = async(e) =>{
    e.preventDefault();
      dispatch(setLoading(true));
      try {
          const response = await apiConnector("post",`${BASE_URL}/task/create-daily-task`,{
            id,
            task,
            lastSubmissionDate
        });

          if(!response.data.success){
              throw new Error(response.data.message);
          }

          toast.success("Daily Task Added Succesfully");
          setLastSubmissionDate('');
          setTask('');
          setId('');

          window.location.reload();

      } catch (error) {
          console.log("Can't add daily task due to",error);
          toast.error(error?.response.data.message);
      }
      dispatch(setLoading(false));

  }


    // get all telecallers
    const getAllTeleCallers = async()=>{
      dispatch(setLoading(true));
      try {
          const response = await apiConnector("get",`${BASE_URL}/telecaller/get-all-telecaller`);

          if(!response.data.success){
              throw new Error(response.data.message);
          }

          setTeleData(response.data.data);

      } catch (error) {
          console.log("Can't fetch Data due to",error);
      }
      dispatch(setLoading(false));
  }

  useEffect(()=>{
    getAllTeleCallers();
  },[])

  const [allTasks, setAllTasks] = useState([]);

//   Get All task
  const getAllDailyTasks = async()=>{
    dispatch(setLoading(true));
    try {
        const response = await apiConnector("get",`${BASE_URL}/task/get-all-tasks`);

        if(!response.data.success){
            throw new Error(response.data.message);
        }

        setAllTasks(response.data.data);

    } catch (error) {
        console.log("Can't fetch Tasks due to",error);
    }
    dispatch(setLoading(false));
}

    useEffect(()=>{
    getAllDailyTasks();
    },[]);

    console.log(allTasks);

  return (
    <div className=' flex flex-col gap-10  ' >
        {
            loading && (<Loader/>)
        }
        {
            !loading && (
            <div className=' flex flex-col gap-10  ' >
                <form className='flex flex-col gap-5' >
            <div className=' grid grid-cols-3 gap-5 ' >
            <div className='flex flex-col ' >
                        <label htmlFor={id} >
                        Users List:
                        </label>
                        <select value={id} 
                        className=' w-full rounded-[0.5rem] text-sm outline-none bg-gray-200 p-[12px] pr-10 text-black border-b-2 border-gray-500'
                        onChange={(e) => setId(e.target.value)}>
                        <option value=''>Select User</option>
                        {teleData?.map((tele, index) => (
                            <option key={index} value={tele?._id} >
                            {tele?.fullName}
                            </option>
                        ))}
                        </select>
                        </div>
                <div>
                    <label htmlFor='task' >
                        <p className=' text-md text-richblack-5 mb-1 leading-[1.375rem]' >Assign Task <sup className='text-[#FF0000] ' >*</sup> </p>  </label>
                        <input
                            required
                            type='task'
                            id='task'
                            name='task'
                            placeholder='Enter task'
                            value={task}
                            onChange={(e)=> setTask(e.target.value)}
                            className="w-full rounded-[0.5rem] text-sm outline-none bg-gray-200 p-[12px] pr-10 text-black border-b-2 border-gray-500"
                        />
                </div>
                <div>
                    <label htmlFor='lastSubmissionDate' >
                        <p className=' text-md text-richblack-5 mb-1 leading-[1.375rem]' >Last Submission Date</p>  </label>
                        <input value={lastSubmissionDate} type='Date'
                        className=' w-full rounded-[0.5rem] text-sm outline-none bg-gray-200 p-[12px] pr-10 text-black border-b-2 border-gray-500'
                        onChange={(e)=>setLastSubmissionDate(e.target.value)}/>
                </div>
            </div>
            <button onClick={handleSubmit}
            className=' self-center bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-all duration-200 '
            >Submit</button>
                </form>

                <div className=' flex flex-col gap-5 mb-[50px] ' >
                    <h1 className=' text-md md:text-lg font-semibold ' >All Assigned Tasks</h1>
                    <div className=' h-[1px] w-full bg-slate-300 ' ></div>
                                        <div className="p-4">
                        <table className="table-auto w-full border border-blue-600">
                            <thead>
                            <tr className="bg-blue-500 text-white text-sm ">
                                <th className="px-4 py-2 border">S.No</th>
                                <th className="px-4 py-2 border">Task</th>
                                <th className="px-4 py-2 border">Assigned To</th>
                                <th className="px-4 py-2 border">Assigned On</th>
                                <th className="px-4 py-2 border">Completion %</th>
                                <th className="px-4 py-2 border">Last Completion Date</th>
                                <th className="px-4 py-2 border">Task Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {/* Loop through each user */}
                            {
                                allTasks?.map((task, index) => (
                                <tr key={index} className="hover:bg-gray-50 text-[12px] ">
                                <td className="border px-4 py-2">{index+1}.</td>
                                <td className="border px-4 py-2">{task?.task}</td>
                                <td className="border px-4 py-2">{task?.assignedTo?.fullName}</td>
                                <td className="border px-4 py-2">{ formateDate(task?.assignedOn) }</td>
                                <td className="border px-4 py-2">
                                <TaskCompletionStatus completionPercentage={task?.completionPercentage}/>
                                </td>
                                <td className="border px-4 py-2">{ formateDate(task?.lastSubmissionDate) }</td>
                                <td className={`border px-4 py-2 font-semibold ${task?.currentStatus === 'Completed' ? "text-green-500" : "text-red-500" } `}>{
                                    task?.currentStatus
                                }</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        </div>
                </div>

            </div>
)
        }
    </div>
  )
}

export default DailyTask