import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './CalendarStyles.css'; // Custom styling for calendar to override default styles

const AttendanceCalendar = ({ attendance }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  // Convert attendance data to a map for easy lookup
  const attendanceMap = attendance.reduce((map, entry) => {
    const date = new Date(entry.date).toDateString();
    map[date] = entry.isPresent;
    return map;
  }, {});

  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
      const dateString = date.toDateString();
      if (attendanceMap[dateString] === true) {
        return 'bg-green-500 text-white'; // Present days marked in green
      } else if (attendanceMap[dateString] === false) {
        return 'bg-red-500 text-white'; // Absent days marked in red
      }
    }
    return '';
  };

  return (
    <div className="p-4">
      <Calendar
        onChange={setSelectedDate}
        value={selectedDate}
        tileClassName={tileClassName}
      />
    </div>
  );
};

export default AttendanceCalendar;
